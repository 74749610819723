import React, { useCallback } from "react"
import * as s from "./styles.module.css"
import CLink from "../../cLink"
import classnames from "classnames"

const MenuItemsEnlaceExterno = ({ pages, closeMenuMobile, isWhite }) => {
  const externalPages = pages.filter(
    p => p.context.template === "page_enlace_externo"
  )
  const internalPages = pages.filter(
    p => p.context.template !== "page_enlace_externo"
  )

  return (
    <div className={s.container}>

      <div
        className={classnames(s.separacionArea, {
          [s.isWhite]: isWhite,
        })}
      >
        <div className={s.separacion} />
      </div>

      <div className={classnames(s.external, {
        [s.isWhite]: isWhite,
      })}>
        <nav className={s.menuPrinicipal}>
          {externalPages.map(page => (
            <a
              key={page.path}
              onClick={closeMenuMobile}
              href={page.context.external_link}
            >
              <span>{page.context.title}</span>
            </a>
          ))}
        </nav>
      </div>
      <div
        className={classnames(s.internal, {
          [s.isWhite]: isWhite,
        })}
      >
        <nav className={s.menuPrinicipal}>
          {internalPages.map(page => {
            if (page.context?.texto_corto1 && page.context.texto_corto1.startsWith("http")) {
              return (
                <a key={page.path} onClick={closeMenuMobile} href={page.context.texto_corto1}>
                  {page.context.title}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={15}
                    height={17}
                    fill="none"
                    viewBox="0 0 15 17"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.793 1.5h-2.2v-1h3.803v3.804h-1V2.312l-6.811 6.81-.707-.707L12.793 1.5ZM.5 3.038h7.57v1H1.5V13.5h9.462V6.93h1v7.57H.5V3.038Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              )
            }
            return (
              <CLink key={page.path} onClick={closeMenuMobile} to={page.path} >
                {page.context.title}
              </CLink>
            )
          }
          )}
        </nav>
      </div>
    </div >
  )
}

export default MenuItemsEnlaceExterno
